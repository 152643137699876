.footer-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: space-between;
    background-color: rgba(0, 0, 0, 0.925);
    color: rgb(238, 238, 238);
    margin-top: 150px;
}

.footer {
    
width: 300px;
padding: 30px;
margin: 20px;

}