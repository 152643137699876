.header {
    display: flex;
    height: 100px;
    flex-direction: row;
    background-image: linear-gradient(180deg, rgba(217, 230, 241, 0.5), rgb(255, 255, 255));
    justify-content: space-around;
    align-items: center;
}

.title-container {
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}

.title {
    font-family: 'Encode Sans SC', sans-serif;
    font-weight: bold;
    font-size: 40px;
    color: rgb(1, 115, 182);
    text-shadow: 1px 1px 2px rgba(30, 69, 77, 0.76);
}

.description {
    font-family: 'Encode Sans SC', sans-serif;
    font-size: 20px;
    color: rgb(18, 45, 61);
}


.cart-link {
margin-left: 500px;
}
.back-shop-link {
 margin-right: 50px;
}

.back-shop-link:hover, .cart-link:hover {
    transition-timing-function: ease-in-out;
    transition-duration: 500ms;
    transition-delay: 200ms;
    transform: scale(1.1);
}

.description-back:active, .description-cart:active {
    transition-timing-function: ease-in-out;
    transition-duration: 500ms;
    text-shadow: 2px -2px 20px rgba(122, 47, 17, 0.89);
}

a {
    text-decoration: none;
}

.description-cart,
.description-back {
    font-family: 'Encode Sans SC', sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: rgb(1, 115, 182);
    text-shadow: 1px 1px 2px rgba(30, 69, 77, 0.76);
}
.link {
    width: 20px;
    height: 20px;
}