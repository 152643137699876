
.shoping-cart {
    padding: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 20px;
    margin: 30px;
    color: rgb(10, 61, 90);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    /* box-shadow: 20px 10px 10px rgba(0, 128, 128, 0.103) */
}

table {
    border-bottom: 5px solid rgb(1, 115, 182);
    padding: 15px;
    width: auto;
    height: auto;
    text-align: center;
    
}

thead tr th:nth-child(1) {
    color: rgb(65, 0, 0);
}

body tr td:nth-child(1) {
    color: rgb(65, 0, 0);
}

td, th {
    padding: 1px 40px 1px 40px;
}

.add-button, .all-delete-button, .delete-button {
    margin: 5px;
    background-image: linear-gradient(50deg, rgba(153, 94, 221, 0.575), rgba(233, 144, 72, 0.514));
    border: none;
    outline: none;
    box-shadow: 0px 5px 5px 0px rgba(184, 95, 37, 0.192);
    border-radius: 10px;
    width: 50px;
    height: 50px;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    color:rgb(65, 37, 37);
    font-size: 15px;
    font-weight: bold;
}
.all-delete-button {
    font-weight: normal;
    width: 100px;
}

.add-button:hover , .all-delete-button:hover, .delete-button:hover {
    transition-duration: 500ms;
    transform: scale(1.1)
}

.add-button:active , .all-delete-button:active, .delete-button:active {
    
    transition-duration: 200ms;
    /* border: 5px solid rgba(71, 96, 238, 0.685); */
    box-shadow: 0px 0px 10px 10px rgba(184, 95, 37, 0.192);
}

.total {
margin-top: 20px;
 font-size: 25px;
 font-weight: bold;    
}